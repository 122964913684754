import React from "react";

import ImageLists from "../components/image/list";

const ImagePage = () => {
    return (
        <div className="subnavaware-view">
            <ImageLists />
        </div>
    );
};

export default ImagePage;
